/**
 * Basic container for a tool pane in Hadron.
 *
 * Basically a simplified version of the Pane class in Compiler Explorer.
 */
export class Pane {
    hub;
    container;
    resizeObserver;
    // Normal derived class workflow is to call super() to active this constructor,
    // then create UI elements, then call init() to register standard and any extra
    // callbacks. The setup is in two parts to solve for some race conditions between
    // callbacks and UI setup resulting in UI elements not being consistently created
    // by callback time.
    constructor(hub, container) {
        this.hub = hub;
        this.container = container;
        this.resizeObserver = new ResizeObserver(this.resize.bind(this));
    }
    // Call me after building UI elements in derived constructor.
    init() {
        this.registerStandardCallbacks();
        this.registerCallbacks();
    }
    registerStandardCallbacks() {
        this.resizeObserver.observe(this.container.element);
        this.container.on('destroy', () => this.close.bind(this));
        this.container.on('resize', () => this.resize.bind(this));
        this.hub.layout.eventHub.on('destroy', () => this.close.bind(this));
        this.hub.layout.eventHub.on('shown', () => this.resize.bind(this));
        this.hub.layout.eventHub.on('resize', () => this.resize.bind(this));
    }
    registerCallbacks() { }
}
