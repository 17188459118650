import { GoldenLayout } from 'golden-layout';
import * as monaco from 'monaco-editor';
import * as hadron_language_config from './hadron.js';
import { HELP_VIEW_COMPONENT_NAME, EDITOR_COMPONENT_NAME } from './component.interfaces.js';
import { Hub } from './hub.js';
// css
require('./css/goldenlayout-base.css');
require('./css/goldenlayout-light-theme.css');
function start() {
    // Register and configure language with the editor.
    monaco.languages.register({ id: 'hadron' });
    monaco.languages.setLanguageConfiguration('hadron', hadron_language_config.conf);
    monaco.languages.setMonarchTokensProvider('hadron', hadron_language_config.language);
    const layoutContainer = document.querySelector('#root');
    var hub = new Hub(new GoldenLayout(layoutContainer));
    // GoldenLayout
    const layoutConfig = {
        root: {
            type: 'row',
            content: [{
                    type: 'component',
                    componentType: EDITOR_COMPONENT_NAME,
                    title: 'Untitled',
                    isClosable: false, // make not closable until we allow multi-file editing.
                }, {
                    type: 'column',
                    content: [{
                            type: 'component',
                            componentType: HELP_VIEW_COMPONENT_NAME,
                        }]
                }],
        },
    };
    function sizeRoot() {
        const width = window.innerWidth;
        const height = window.innerHeight - layoutContainer.offsetTop;
        hub.layout.setSize(width, height);
    }
    window.onresize = sizeRoot;
    hub.layout.loadLayout(layoutConfig);
    sizeRoot();
}
start();
