import _ from 'underscore';
import * as monaco from 'monaco-editor';
import * as hadron from './hadron-web/hadron_web.js';
import { Pane } from './pane.js';
export class Editor extends Pane {
    editor;
    constructor(hub, container) {
        super(hub, container);
        this.editor = monaco.editor.create(this.container.element, {
            language: 'hadron'
        });
        this.init();
        // Set the intial size of the editor inside the tab or it will size to 0x0.
        this.resize();
    }
    registerCallbacks() {
        // Add cmd+enter keybinding.
        this.editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, () => {
            let model = this.editor.getModel();
            let selection = this.editor.getSelection();
            if (model != null && selection != null && !selection.isEmpty()) {
                let hadron_id = this.hub.monacoIDs.get(model.id);
                if (hadron_id != undefined) {
                    let model_str = hadron.get_text_from_model(hadron_id, selection.startLineNumber - 1, selection.startColumn - 1, selection.endLineNumber - 1, selection.endColumn - 1);
                    console.log(model_str);
                }
            }
        });
    }
    resize() {
        _.defer(() => {
            const width = this.container.width;
            const height = this.container.height;
            this.editor.layout({
                width: width,
                height: height
            });
        });
    }
    close() {
        this.editor.dispose();
    }
}
