import * as monaco from 'monaco-editor';
import * as hadron from './hadron-web/hadron_web.js';
import { HELP_VIEW_COMPONENT_NAME, EDITOR_COMPONENT_NAME, POST_VIEW_COMPONENT_NAME } from './component.interfaces.js';
import { HelpView } from './help-view.js';
import { Editor } from './editor.js';
import { PostView } from './post-view.js';
/**
 * Serves as a Controller between the Hadron Models and the Editor Views.
 *
 * Keeps a map of the relationships between open editors and Hadron text models, and
 * coordinates connecting those two. Binds the layout to the component creation
 * methods. Owns the GoldenLayout object.
 */
export class Hub {
    layout;
    editors;
    // Map of Monaco text model IDs to Hadron text model IDs.
    monacoIDs;
    constructor(layout) {
        this.layout = layout;
        this.editors = [];
        this.monacoIDs = new Map();
        monaco.editor.onDidCreateModel((model) => this.addModel(model));
        layout.registerComponentFactoryFunction(HELP_VIEW_COMPONENT_NAME, (container, _itemConfig) => this.createHelpViewComponent(container));
        layout.registerComponentFactoryFunction(EDITOR_COMPONENT_NAME, (container, _itemConfig) => this.createEditorComponent(container));
        layout.registerComponentFactoryFunction(POST_VIEW_COMPONENT_NAME, (container, _itemConfig) => this.createPostViewComponent(container));
    }
    addModel(model) {
        const hadronID = hadron.create_model(model.getValue());
        this.monacoIDs.set(model.id, hadronID);
        model.onDidChangeContent((event) => this.updateHadronModel(event, hadronID));
    }
    updateHadronModel(event, hadronID) {
        // Note: these events are using 1-based column and line numbers, and Hadron uses 0-based positions.
        for (let change of event.changes) {
            // a) Insertion of new text: range start and end are equal, text is nonempty.
            if (change.range.startColumn == change.range.endColumn &&
                change.range.startLineNumber == change.range.endLineNumber) {
                if (change.text.length > 0) {
                    hadron.insert_text_to_model(hadronID, change.range.startLineNumber - 1, change.range.startColumn - 1, change.text);
                }
                continue;
            }
            // b) Removal of existing text: range start and end are not equal, text is empty.
            if (change.text.length == 0) {
                hadron.remove_text_from_model(hadronID, change.range.startLineNumber - 1, change.range.startColumn - 1, change.range.endLineNumber - 1, change.range.endColumn - 1);
                continue;
            }
            // c) replacement of text: range start and end not equal, text is non-empty.
            hadron.replace_text_in_model(hadronID, change.range.startLineNumber - 1, change.range.startColumn - 1, change.range.endLineNumber - 1, change.range.endColumn - 1, change.text);
        }
    }
    createHelpViewComponent(container) {
        new HelpView(this, container);
    }
    createEditorComponent(container) {
        const editor = new Editor(this, container);
        this.editors.push(editor);
    }
    createPostViewComponent(container) {
        new PostView(this, container);
    }
}
